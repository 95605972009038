import { RiArrowDownSLine } from "@remixicon/react"
import { Link, navigate } from "gatsby"
import React, { Dispatch, ReactElement, useEffect, useState } from "react"
import * as config from "../../config"
import { User } from "../../domain/models"
import { useAuth } from "../../middleware/AuthGuard"
import { SearchFilterType } from "../../types"
import * as styles from "./mobile-search-header.module.scss"
import { SearchFilterChip, SearchInputBar } from "."

interface Props {
  searchFilters: (keyof typeof SearchFilterType)[]
  onSelectFilter: (type: keyof typeof SearchFilterType) => void
  onDeselectFilter: (type: keyof typeof SearchFilterType) => void
}

export const MobileSearchHeader = (props: Props): ReactElement => {
  const { user, logout } = useAuth()

  const searchFilterChip = (
    type: keyof typeof SearchFilterType
  ): ReactElement => (
    <SearchFilterChip
      type={type}
      isSelected={props.searchFilters.includes(type)}
      onSelect={props.onSelectFilter}
      onDeselect={props.onDeselectFilter}
    />
  )

  return (
    <header className={styles.header}>
      <div className={styles.upperRow}>
        <div className={styles.serviceName}>
          <Link to="/">
            <img src="/QT-net.webp" alt="logo" width={48} height={20} />
          </Link>
        </div>
        <div className={styles.menu}>
          {user ? (
            <UserMenu
              user={user}
              onReservationStatusClick={async () =>
                await navigate("/reservation-detail")
              }
              onLogoutClick={logout}
            />
          ) : (
            <DefaultMenu />
          )}
        </div>
      </div>
      <div className={styles.searchInput}>
        <SearchInputBar />
      </div>
      <div className={styles.lowerRow}>
        {searchFilterChip("AVAILABLE")}
        {searchFilterChip("QT_NET_RESEVABLE")}
        {searchFilterChip("QT_NET_POINT")}
        {searchFilterChip("QT_NET_SMART_PAY")}
        {searchFilterChip("WHEELCHAIR")}
      </div>
    </header>
  )
}

const DefaultMenu = (): ReactElement => {
  return (
    <>
      <Link to="/signin" className={styles.menuItem}>
        ログイン
      </Link>
      <a href={`${config.QTNET_BASE_URL}/signup`} className={styles.menuItem}>
        新規登録
      </a>
    </>
  )
}

const UserMenu = ({
  user,
  onReservationStatusClick,
  onLogoutClick,
}: {
  user: User
  onReservationStatusClick: Dispatch<void>
  onLogoutClick: Dispatch<void>
}): ReactElement => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      isDropdownOpen &&
      !event
        .composedPath()
        .some(el => (el as HTMLElement).classList?.contains(styles.userSection))
    ) {
      setIsDropdownOpen(false)
    }
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isDropdownOpen])

  return (
    <div className={styles.userSection}>
      <div className={styles.userInfo} onClick={toggleDropdown}>
        <p>{user.name}様</p>
        <RiArrowDownSLine size="1em" />
      </div>
      {isDropdownOpen && (
        <div className={styles.dropdownMenu}>
          <button
            onClick={() => {
              setIsDropdownOpen(false)
              onReservationStatusClick()
            }}
            className={styles.dropdownItem}
          >
            予約ステータス
          </button>
          <button
            onClick={() => {
              setIsDropdownOpen(false)
              onLogoutClick()
            }}
            className={styles.dropdownItem}
          >
            ログアウト
          </button>
        </div>
      )}
    </div>
  )
}
