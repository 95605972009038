import React, { ReactElement, ReactNode } from "react"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import * as styles from "./content-layout.module.scss"
import { Footer } from "./footer"
import { Header } from "./header"

interface ContentLayoutProps {
  children: ReactNode
  currentParking?: Parking
}

export const ContentLayout = ({
  children,
  currentParking,
}: ContentLayoutProps): ReactElement => {
  return (
    <div className={styles.body}>
      <Header currentParking={currentParking} />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  )
}
