import {
  APILoadingStatus,
  APIProvider,
  AdvancedMarker,
  Map as GoogleMap,
  useApiLoadingStatus,
  Pin,
} from "@vis.gl/react-google-maps"
import React, { ReactElement, useMemo, useState } from "react"
import MoonLoader from "react-spinners/MoonLoader"
import { LatLng } from "../../__generated__/proto/google/type/latlng"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import * as config from "../../config"
import * as styles from "./map.module.scss"
import { ParkingPin } from "."

interface Props {
  width: string
  height: string
  center: LatLng | null
  zoom: number
  parkings?: Parking[]
  selectedParking?: Parking
  showCenterMarker: boolean
  onSelectParking?: (parking: Parking) => void
  onCenterChanged: (latLng: LatLng) => void
  onZoomChanged?: (zoom: number) => void
  onBoundsLoaded?: (bounds: google.maps.LatLngBounds) => void
  onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void
}

export const Map = React.memo((props: Props): ReactElement => {
  // MARK: - Variables

  const status = useApiLoadingStatus()

  const loadError = useMemo(() => {
    return (
      status === APILoadingStatus.FAILED ||
      status === APILoadingStatus.AUTH_FAILURE
    )
  }, [status])

  // MARK: - States

  const [boundsLoaded, setBoundsLoaded] = useState(false)

  // MARK: - Render

  if (props.center === null) {
    return (
      <div className={styles.placeholderContainer}>
        <MoonLoader color="#262e38" size={32} />
      </div>
    )
  }

  const center: google.maps.LatLngLiteral = {
    lat: props.center.latitude,
    lng: props.center.longitude,
  }

  if (!config.GOOGLE_MAPS_API_KEY || loadError) {
    return (
      <div className={styles.placeholderContainer}>
        <p className={styles.placeholderText}>
          マップを読み込めませんでした <br />
          時間をおいて再度お試しください
          <br />
        </p>
      </div>
    )
  }

  return (
    <APIProvider apiKey={config.GOOGLE_MAPS_API_KEY} region="JP" language="ja">
      <GoogleMap
        mapId={config.GOOGLE_MAPS_MAP_ID}
        style={{
          width: props.width,
          height: props.height,
        }}
        defaultCenter={center}
        defaultZoom={props.zoom}
        disableDefaultUI
        clickableIcons={false}
        onCenterChanged={ev => {
          const currentCenter = ev.detail.center
          props.onCenterChanged({
            latitude: currentCenter.lat,
            longitude: currentCenter.lng,
          })
        }}
        onZoomChanged={ev => {
          const currentZoom = ev.map.getZoom()
          const currentCenter = ev.map.getCenter()
          if (
            props.onZoomChanged &&
            currentZoom &&
            currentZoom !== props.zoom
          ) {
            props.onZoomChanged(currentZoom)
          }
          if (currentCenter) {
            props.onCenterChanged({
              latitude: currentCenter.lat(),
              longitude: currentCenter.lng(),
            })
          }
        }}
        onBoundsChanged={ev => {
          const mapBounds = ev.map.getBounds()
          if (mapBounds && !boundsLoaded && props.onBoundsLoaded) {
            props.onBoundsLoaded(mapBounds)
            setBoundsLoaded(true)
          }
          if (mapBounds && props.onBoundsChanged) {
            props.onBoundsChanged(mapBounds)
          }
        }}
      >
        {props.showCenterMarker && (
          <AdvancedMarker className="parking-marker" position={center}>
            <Pin />
          </AdvancedMarker>
        )}
        {props.parkings
          ?.filter(parking => parking.name !== props.selectedParking?.name)
          .map(parking => (
            <ParkingOverlayPin
              key={parking.name}
              parking={parking}
              isSelected={false}
              onSelect={parking =>
                props.onSelectParking && props.onSelectParking(parking)
              }
            />
          ))}
        {
          // NOTE: Bring the pin of selected parking to front
          props.selectedParking && (
            <ParkingOverlayPin
              parking={props.selectedParking}
              isSelected={true}
              onSelect={parking =>
                props.onSelectParking && props.onSelectParking(parking)
              }
            />
          )
        }
      </GoogleMap>
    </APIProvider>
  )
})

const ParkingOverlayPin = React.memo(
  (props: {
    parking: Parking
    isSelected: boolean
    onSelect: (parking: Parking) => void
  }): ReactElement => {
    const { parking, isSelected, onSelect } = props
    return (
      <AdvancedMarker
        className="parking-marker"
        key={parking.name}
        clickable={true}
        position={
          parking.latLng && {
            lat: parking.latLng.latitude,
            lng: parking.latLng.longitude,
          }
        }
      >
        <ParkingPin
          parking={parking}
          isSelected={isSelected}
          onSelect={onSelect}
        />
      </AdvancedMarker>
    )
  }
)
