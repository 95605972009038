import { Address } from "../models"
import { UseCase } from "./use-case"

interface Props {
  addresses: Address[]
  prefecture: string
}

export class GetCitiesUseCase implements UseCase<Props, string[]> {
  call({ addresses, prefecture }: Props): string[] {
    return (
      addresses
        .filter(address => address.prefecture === prefecture)
        // Remove duplicated cities
        .filter(
          (address, index, addresses) =>
            addresses.findIndex(add => add.city === address.city) === index
        )
        .sort((a, b) => a.cityKana.localeCompare(b.cityKana))
        .map(address => address.city)
    )
  }
}
