import React, { ReactElement, ReactNode } from "react"
import { UniversalMediaQuery } from "./universal-media-query"
import "../../../src/global.scss"

interface LayoutProps {
  children: ReactNode
}

export const Layout = ({ children }: LayoutProps): ReactElement => (
  <UniversalMediaQuery minWidth={0}>
    {/* NOTE: This div class is needed to watch if UniversalMediaQuery component is ready */}
    <div className="cypress" data-cy="MediaQueryLoaded">
      {children}
    </div>
  </UniversalMediaQuery>
)
