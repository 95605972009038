// extracted by mini-css-extract-plugin
export var bottomBorder = "search-result-list-item-module--bottomBorder--8761a";
export var container = "search-result-list-item-module--container--d5eb0";
export var crowdedStatusItem = "search-result-list-item-module--crowdedStatusItem--b39f5";
export var emptyStatusItem = "search-result-list-item-module--emptyStatusItem--7d33e";
export var fullStatusItem = "search-result-list-item-module--fullStatusItem--89d99";
export var infoTable = "search-result-list-item-module--infoTable--571f8";
export var invalidStatusItem = "search-result-list-item-module--invalidStatusItem--89327";
export var parkingNameLink = "search-result-list-item-module--parkingNameLink--8ad32";
export var serviceIcons = "search-result-list-item-module--serviceIcons--e6f3b";
export var statusItemText = "search-result-list-item-module--statusItemText--26d45";
export var wrapper = "search-result-list-item-module--wrapper--e65be";