// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               unknown
// source: itech/motorist/pksha/v1/transaction.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { Money } from "../../../../google/type/money";
import { Parking } from "./parking";

export const protobufPackage = "itech.motorist.pksha.v1";

/**
 * A series of related events.
 *
 * A transaction represents a finite state machine of a parking space
 * reservation.
 */
export interface Transaction {
  /**
   * The resource name for the transaction.
   *
   * Format: transactions/{transaction}
   */
  name: string;
  /** The client-generated reservation request id that created this transaction. */
  reservationRequestId: string;
  /** The parking space name for the transaction. */
  parkingSpaceName: string;
  /** The billing amount of the parking fee. */
  fee?:
    | Money
    | undefined;
  /** Series of events from older to newer. */
  events: Transaction_Event[];
  /** The parking of the transaction. */
  parking?:
    | Parking
    | undefined;
  /** Whether the transaction is used as ParkingGo. */
  parkingGoUsage: Transaction_ParkingGoUsage;
}

/** A representation of a result of payment processing. */
export enum Transaction_PaymentProcessingResult {
  PAYMENT_PROCESSING_RESULT_INVALID = "PAYMENT_PROCESSING_RESULT_INVALID",
  /**
   * PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS - Payment was processed automatically (a.k.a. "自動精算")
   * and it was succeed.
   */
  PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS = "PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS",
  /**
   * PAYMENT_PROCESSING_RESULT_AUTO_FAILURE - Payment was processed automatically (a.k.a. "自動精算")
   * and it was failed.
   */
  PAYMENT_PROCESSING_RESULT_AUTO_FAILURE = "PAYMENT_PROCESSING_RESULT_AUTO_FAILURE",
  /**
   * PAYMENT_PROCESSING_RESULT_SEMI_AUTO - Payment was recognized as semi-automatic payment (a.k.a. "遅延精算")
   * and was not proceeded.
   */
  PAYMENT_PROCESSING_RESULT_SEMI_AUTO = "PAYMENT_PROCESSING_RESULT_SEMI_AUTO",
}

export function transaction_PaymentProcessingResultFromJSON(object: any): Transaction_PaymentProcessingResult {
  switch (object) {
    case 0:
    case "PAYMENT_PROCESSING_RESULT_INVALID":
      return Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID;
    case 1:
    case "PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS":
      return Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS;
    case 2:
    case "PAYMENT_PROCESSING_RESULT_AUTO_FAILURE":
      return Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_FAILURE;
    case 3:
    case "PAYMENT_PROCESSING_RESULT_SEMI_AUTO":
      return Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_SEMI_AUTO;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transaction_PaymentProcessingResult");
  }
}

export function transaction_PaymentProcessingResultToJSON(object: Transaction_PaymentProcessingResult): string {
  switch (object) {
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID:
      return "PAYMENT_PROCESSING_RESULT_INVALID";
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS:
      return "PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS";
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_FAILURE:
      return "PAYMENT_PROCESSING_RESULT_AUTO_FAILURE";
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_SEMI_AUTO:
      return "PAYMENT_PROCESSING_RESULT_SEMI_AUTO";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transaction_PaymentProcessingResult");
  }
}

export function transaction_PaymentProcessingResultToNumber(object: Transaction_PaymentProcessingResult): number {
  switch (object) {
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID:
      return 0;
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS:
      return 1;
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_FAILURE:
      return 2;
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_SEMI_AUTO:
      return 3;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transaction_PaymentProcessingResult");
  }
}

/**
 * ParkingGoUsage is the status of using ParkingGo. Users can switch the
 * status.
 */
export enum Transaction_ParkingGoUsage {
  PARKING_GO_USAGE_INVALID = "PARKING_GO_USAGE_INVALID",
  PARKING_GO_USAGE_ACTIVE = "PARKING_GO_USAGE_ACTIVE",
  PARKING_GO_USAGE_INACTIVE = "PARKING_GO_USAGE_INACTIVE",
}

export function transaction_ParkingGoUsageFromJSON(object: any): Transaction_ParkingGoUsage {
  switch (object) {
    case 0:
    case "PARKING_GO_USAGE_INVALID":
      return Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID;
    case 1:
    case "PARKING_GO_USAGE_ACTIVE":
      return Transaction_ParkingGoUsage.PARKING_GO_USAGE_ACTIVE;
    case 2:
    case "PARKING_GO_USAGE_INACTIVE":
      return Transaction_ParkingGoUsage.PARKING_GO_USAGE_INACTIVE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transaction_ParkingGoUsage");
  }
}

export function transaction_ParkingGoUsageToJSON(object: Transaction_ParkingGoUsage): string {
  switch (object) {
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID:
      return "PARKING_GO_USAGE_INVALID";
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_ACTIVE:
      return "PARKING_GO_USAGE_ACTIVE";
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_INACTIVE:
      return "PARKING_GO_USAGE_INACTIVE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transaction_ParkingGoUsage");
  }
}

export function transaction_ParkingGoUsageToNumber(object: Transaction_ParkingGoUsage): number {
  switch (object) {
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID:
      return 0;
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_ACTIVE:
      return 1;
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_INACTIVE:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Transaction_ParkingGoUsage");
  }
}

/** Event is an envelope of concrete events such as Reserved. */
export interface Transaction_Event {
  /** The reservation event. */
  reserved?:
    | Transaction_Event_Reserved
    | undefined;
  /** The reservation cancel event. */
  reservationCanceled?:
    | Transaction_Event_ReservationCanceled
    | undefined;
  /** The reservation cancel event. */
  reservationExpired?:
    | Transaction_Event_ReservationExpired
    | undefined;
  /** The entry detection event. */
  entryDetected?:
    | Transaction_Event_EntryDetected
    | undefined;
  /** The entry event. */
  entered?:
    | Transaction_Event_Entered
    | undefined;
  /** The entry acceptance event. */
  entryAccepted?:
    | Transaction_Event_EntryAccepted
    | undefined;
  /** The entry rejection event. */
  entryRejected?:
    | Transaction_Event_EntryRejected
    | undefined;
  /** The exit event. */
  exited?:
    | Transaction_Event_Exited
    | undefined;
  /** The exit event. */
  aborted?:
    | Transaction_Event_Aborted
    | undefined;
  /** The paid event. */
  paid?: Transaction_Event_Paid | undefined;
}

/** A representation of reservation. */
export interface Transaction_Event_Reserved {
  /**
   * The resource name for the reservation.
   *
   * Format:
   * transactions/{transaction}/reservations/{reservation}
   */
  name: string;
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

/** A representation of reservation cancel event. */
export interface Transaction_Event_ReservationCanceled {
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

/** A representation of reservation expiration event. */
export interface Transaction_Event_ReservationExpired {
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

/** A representation of entry detection event. */
export interface Transaction_Event_EntryDetected {
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

/** A representation of entry event. */
export interface Transaction_Event_Entered {
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

/** A representation of entry acceptance event. */
export interface Transaction_Event_EntryAccepted {
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

/** A representation of entry rejection event. */
export interface Transaction_Event_EntryRejected {
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

/** A representation of exit event. */
export interface Transaction_Event_Exited {
  /**
   * The creation timestamp of this event.
   *
   * This field is set when a car exited from the parking space without
   * using the local adjustment machine. Note that this field can be empty
   * when the parking bill is settled by the local adjustment machine
   * in the parking.
   */
  createTime?:
    | Date
    | undefined;
  /** The payment processing result. */
  paymentProcessingResult: Transaction_PaymentProcessingResult;
}

/** A representation of aborted event. */
export interface Transaction_Event_Aborted {
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

/**
 * A representation of payment event.
 *
 * This field will be set by next actions.
 *
 * 1. auto system payment
 * 2. settlement by user
 */
export interface Transaction_Event_Paid {
  /** The creation timestamp of this event. */
  createTime?: Date | undefined;
}

function createBaseTransaction(): Transaction {
  return {
    name: "",
    reservationRequestId: "",
    parkingSpaceName: "",
    fee: undefined,
    events: [],
    parking: undefined,
    parkingGoUsage: Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID,
  };
}

export const Transaction: MessageFns<Transaction> = {
  encode(message: Transaction, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.reservationRequestId !== "") {
      writer.uint32(18).string(message.reservationRequestId);
    }
    if (message.parkingSpaceName !== "") {
      writer.uint32(26).string(message.parkingSpaceName);
    }
    if (message.fee !== undefined) {
      Money.encode(message.fee, writer.uint32(34).fork()).join();
    }
    for (const v of message.events) {
      Transaction_Event.encode(v!, writer.uint32(42).fork()).join();
    }
    if (message.parking !== undefined) {
      Parking.encode(message.parking, writer.uint32(50).fork()).join();
    }
    if (message.parkingGoUsage !== Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID) {
      writer.uint32(56).int32(transaction_ParkingGoUsageToNumber(message.parkingGoUsage));
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.reservationRequestId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.parkingSpaceName = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.fee = Money.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.events.push(Transaction_Event.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.parking = Parking.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.parkingGoUsage = transaction_ParkingGoUsageFromJSON(reader.int32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      reservationRequestId: isSet(object.reservationRequestId) ? globalThis.String(object.reservationRequestId) : "",
      parkingSpaceName: isSet(object.parkingSpaceName) ? globalThis.String(object.parkingSpaceName) : "",
      fee: isSet(object.fee) ? Money.fromJSON(object.fee) : undefined,
      events: globalThis.Array.isArray(object?.events)
        ? object.events.map((e: any) => Transaction_Event.fromJSON(e))
        : [],
      parking: isSet(object.parking) ? Parking.fromJSON(object.parking) : undefined,
      parkingGoUsage: isSet(object.parkingGoUsage)
        ? transaction_ParkingGoUsageFromJSON(object.parkingGoUsage)
        : Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID,
    };
  },

  toJSON(message: Transaction): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.reservationRequestId !== "") {
      obj.reservationRequestId = message.reservationRequestId;
    }
    if (message.parkingSpaceName !== "") {
      obj.parkingSpaceName = message.parkingSpaceName;
    }
    if (message.fee !== undefined) {
      obj.fee = Money.toJSON(message.fee);
    }
    if (message.events?.length) {
      obj.events = message.events.map((e) => Transaction_Event.toJSON(e));
    }
    if (message.parking !== undefined) {
      obj.parking = Parking.toJSON(message.parking);
    }
    if (message.parkingGoUsage !== Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID) {
      obj.parkingGoUsage = transaction_ParkingGoUsageToJSON(message.parkingGoUsage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction>, I>>(base?: I): Transaction {
    return Transaction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction>, I>>(object: I): Transaction {
    const message = createBaseTransaction();
    message.name = object.name ?? "";
    message.reservationRequestId = object.reservationRequestId ?? "";
    message.parkingSpaceName = object.parkingSpaceName ?? "";
    message.fee = (object.fee !== undefined && object.fee !== null) ? Money.fromPartial(object.fee) : undefined;
    message.events = object.events?.map((e) => Transaction_Event.fromPartial(e)) || [];
    message.parking = (object.parking !== undefined && object.parking !== null)
      ? Parking.fromPartial(object.parking)
      : undefined;
    message.parkingGoUsage = object.parkingGoUsage ?? Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID;
    return message;
  },
};

function createBaseTransaction_Event(): Transaction_Event {
  return {
    reserved: undefined,
    reservationCanceled: undefined,
    reservationExpired: undefined,
    entryDetected: undefined,
    entered: undefined,
    entryAccepted: undefined,
    entryRejected: undefined,
    exited: undefined,
    aborted: undefined,
    paid: undefined,
  };
}

export const Transaction_Event: MessageFns<Transaction_Event> = {
  encode(message: Transaction_Event, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.reserved !== undefined) {
      Transaction_Event_Reserved.encode(message.reserved, writer.uint32(10).fork()).join();
    }
    if (message.reservationCanceled !== undefined) {
      Transaction_Event_ReservationCanceled.encode(message.reservationCanceled, writer.uint32(18).fork()).join();
    }
    if (message.reservationExpired !== undefined) {
      Transaction_Event_ReservationExpired.encode(message.reservationExpired, writer.uint32(26).fork()).join();
    }
    if (message.entryDetected !== undefined) {
      Transaction_Event_EntryDetected.encode(message.entryDetected, writer.uint32(74).fork()).join();
    }
    if (message.entered !== undefined) {
      Transaction_Event_Entered.encode(message.entered, writer.uint32(34).fork()).join();
    }
    if (message.entryAccepted !== undefined) {
      Transaction_Event_EntryAccepted.encode(message.entryAccepted, writer.uint32(42).fork()).join();
    }
    if (message.entryRejected !== undefined) {
      Transaction_Event_EntryRejected.encode(message.entryRejected, writer.uint32(50).fork()).join();
    }
    if (message.exited !== undefined) {
      Transaction_Event_Exited.encode(message.exited, writer.uint32(58).fork()).join();
    }
    if (message.aborted !== undefined) {
      Transaction_Event_Aborted.encode(message.aborted, writer.uint32(82).fork()).join();
    }
    if (message.paid !== undefined) {
      Transaction_Event_Paid.encode(message.paid, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.reserved = Transaction_Event_Reserved.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.reservationCanceled = Transaction_Event_ReservationCanceled.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.reservationExpired = Transaction_Event_ReservationExpired.decode(reader, reader.uint32());
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.entryDetected = Transaction_Event_EntryDetected.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.entered = Transaction_Event_Entered.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.entryAccepted = Transaction_Event_EntryAccepted.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.entryRejected = Transaction_Event_EntryRejected.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.exited = Transaction_Event_Exited.decode(reader, reader.uint32());
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.aborted = Transaction_Event_Aborted.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.paid = Transaction_Event_Paid.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event {
    return {
      reserved: isSet(object.reserved) ? Transaction_Event_Reserved.fromJSON(object.reserved) : undefined,
      reservationCanceled: isSet(object.reservationCanceled)
        ? Transaction_Event_ReservationCanceled.fromJSON(object.reservationCanceled)
        : undefined,
      reservationExpired: isSet(object.reservationExpired)
        ? Transaction_Event_ReservationExpired.fromJSON(object.reservationExpired)
        : undefined,
      entryDetected: isSet(object.entryDetected)
        ? Transaction_Event_EntryDetected.fromJSON(object.entryDetected)
        : undefined,
      entered: isSet(object.entered) ? Transaction_Event_Entered.fromJSON(object.entered) : undefined,
      entryAccepted: isSet(object.entryAccepted)
        ? Transaction_Event_EntryAccepted.fromJSON(object.entryAccepted)
        : undefined,
      entryRejected: isSet(object.entryRejected)
        ? Transaction_Event_EntryRejected.fromJSON(object.entryRejected)
        : undefined,
      exited: isSet(object.exited) ? Transaction_Event_Exited.fromJSON(object.exited) : undefined,
      aborted: isSet(object.aborted) ? Transaction_Event_Aborted.fromJSON(object.aborted) : undefined,
      paid: isSet(object.paid) ? Transaction_Event_Paid.fromJSON(object.paid) : undefined,
    };
  },

  toJSON(message: Transaction_Event): unknown {
    const obj: any = {};
    if (message.reserved !== undefined) {
      obj.reserved = Transaction_Event_Reserved.toJSON(message.reserved);
    }
    if (message.reservationCanceled !== undefined) {
      obj.reservationCanceled = Transaction_Event_ReservationCanceled.toJSON(message.reservationCanceled);
    }
    if (message.reservationExpired !== undefined) {
      obj.reservationExpired = Transaction_Event_ReservationExpired.toJSON(message.reservationExpired);
    }
    if (message.entryDetected !== undefined) {
      obj.entryDetected = Transaction_Event_EntryDetected.toJSON(message.entryDetected);
    }
    if (message.entered !== undefined) {
      obj.entered = Transaction_Event_Entered.toJSON(message.entered);
    }
    if (message.entryAccepted !== undefined) {
      obj.entryAccepted = Transaction_Event_EntryAccepted.toJSON(message.entryAccepted);
    }
    if (message.entryRejected !== undefined) {
      obj.entryRejected = Transaction_Event_EntryRejected.toJSON(message.entryRejected);
    }
    if (message.exited !== undefined) {
      obj.exited = Transaction_Event_Exited.toJSON(message.exited);
    }
    if (message.aborted !== undefined) {
      obj.aborted = Transaction_Event_Aborted.toJSON(message.aborted);
    }
    if (message.paid !== undefined) {
      obj.paid = Transaction_Event_Paid.toJSON(message.paid);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event>, I>>(base?: I): Transaction_Event {
    return Transaction_Event.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event>, I>>(object: I): Transaction_Event {
    const message = createBaseTransaction_Event();
    message.reserved = (object.reserved !== undefined && object.reserved !== null)
      ? Transaction_Event_Reserved.fromPartial(object.reserved)
      : undefined;
    message.reservationCanceled = (object.reservationCanceled !== undefined && object.reservationCanceled !== null)
      ? Transaction_Event_ReservationCanceled.fromPartial(object.reservationCanceled)
      : undefined;
    message.reservationExpired = (object.reservationExpired !== undefined && object.reservationExpired !== null)
      ? Transaction_Event_ReservationExpired.fromPartial(object.reservationExpired)
      : undefined;
    message.entryDetected = (object.entryDetected !== undefined && object.entryDetected !== null)
      ? Transaction_Event_EntryDetected.fromPartial(object.entryDetected)
      : undefined;
    message.entered = (object.entered !== undefined && object.entered !== null)
      ? Transaction_Event_Entered.fromPartial(object.entered)
      : undefined;
    message.entryAccepted = (object.entryAccepted !== undefined && object.entryAccepted !== null)
      ? Transaction_Event_EntryAccepted.fromPartial(object.entryAccepted)
      : undefined;
    message.entryRejected = (object.entryRejected !== undefined && object.entryRejected !== null)
      ? Transaction_Event_EntryRejected.fromPartial(object.entryRejected)
      : undefined;
    message.exited = (object.exited !== undefined && object.exited !== null)
      ? Transaction_Event_Exited.fromPartial(object.exited)
      : undefined;
    message.aborted = (object.aborted !== undefined && object.aborted !== null)
      ? Transaction_Event_Aborted.fromPartial(object.aborted)
      : undefined;
    message.paid = (object.paid !== undefined && object.paid !== null)
      ? Transaction_Event_Paid.fromPartial(object.paid)
      : undefined;
    return message;
  },
};

function createBaseTransaction_Event_Reserved(): Transaction_Event_Reserved {
  return { name: "", createTime: undefined };
}

export const Transaction_Event_Reserved: MessageFns<Transaction_Event_Reserved> = {
  encode(message: Transaction_Event_Reserved, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_Reserved {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_Reserved();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_Reserved {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
    };
  },

  toJSON(message: Transaction_Event_Reserved): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_Reserved>, I>>(base?: I): Transaction_Event_Reserved {
    return Transaction_Event_Reserved.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_Reserved>, I>>(object: I): Transaction_Event_Reserved {
    const message = createBaseTransaction_Event_Reserved();
    message.name = object.name ?? "";
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

function createBaseTransaction_Event_ReservationCanceled(): Transaction_Event_ReservationCanceled {
  return { createTime: undefined };
}

export const Transaction_Event_ReservationCanceled: MessageFns<Transaction_Event_ReservationCanceled> = {
  encode(message: Transaction_Event_ReservationCanceled, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_ReservationCanceled {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_ReservationCanceled();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_ReservationCanceled {
    return { createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined };
  },

  toJSON(message: Transaction_Event_ReservationCanceled): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_ReservationCanceled>, I>>(
    base?: I,
  ): Transaction_Event_ReservationCanceled {
    return Transaction_Event_ReservationCanceled.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_ReservationCanceled>, I>>(
    object: I,
  ): Transaction_Event_ReservationCanceled {
    const message = createBaseTransaction_Event_ReservationCanceled();
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

function createBaseTransaction_Event_ReservationExpired(): Transaction_Event_ReservationExpired {
  return { createTime: undefined };
}

export const Transaction_Event_ReservationExpired: MessageFns<Transaction_Event_ReservationExpired> = {
  encode(message: Transaction_Event_ReservationExpired, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_ReservationExpired {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_ReservationExpired();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_ReservationExpired {
    return { createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined };
  },

  toJSON(message: Transaction_Event_ReservationExpired): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_ReservationExpired>, I>>(
    base?: I,
  ): Transaction_Event_ReservationExpired {
    return Transaction_Event_ReservationExpired.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_ReservationExpired>, I>>(
    object: I,
  ): Transaction_Event_ReservationExpired {
    const message = createBaseTransaction_Event_ReservationExpired();
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

function createBaseTransaction_Event_EntryDetected(): Transaction_Event_EntryDetected {
  return { createTime: undefined };
}

export const Transaction_Event_EntryDetected: MessageFns<Transaction_Event_EntryDetected> = {
  encode(message: Transaction_Event_EntryDetected, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_EntryDetected {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_EntryDetected();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_EntryDetected {
    return { createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined };
  },

  toJSON(message: Transaction_Event_EntryDetected): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_EntryDetected>, I>>(base?: I): Transaction_Event_EntryDetected {
    return Transaction_Event_EntryDetected.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_EntryDetected>, I>>(
    object: I,
  ): Transaction_Event_EntryDetected {
    const message = createBaseTransaction_Event_EntryDetected();
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

function createBaseTransaction_Event_Entered(): Transaction_Event_Entered {
  return { createTime: undefined };
}

export const Transaction_Event_Entered: MessageFns<Transaction_Event_Entered> = {
  encode(message: Transaction_Event_Entered, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_Entered {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_Entered();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_Entered {
    return { createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined };
  },

  toJSON(message: Transaction_Event_Entered): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_Entered>, I>>(base?: I): Transaction_Event_Entered {
    return Transaction_Event_Entered.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_Entered>, I>>(object: I): Transaction_Event_Entered {
    const message = createBaseTransaction_Event_Entered();
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

function createBaseTransaction_Event_EntryAccepted(): Transaction_Event_EntryAccepted {
  return { createTime: undefined };
}

export const Transaction_Event_EntryAccepted: MessageFns<Transaction_Event_EntryAccepted> = {
  encode(message: Transaction_Event_EntryAccepted, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_EntryAccepted {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_EntryAccepted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_EntryAccepted {
    return { createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined };
  },

  toJSON(message: Transaction_Event_EntryAccepted): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_EntryAccepted>, I>>(base?: I): Transaction_Event_EntryAccepted {
    return Transaction_Event_EntryAccepted.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_EntryAccepted>, I>>(
    object: I,
  ): Transaction_Event_EntryAccepted {
    const message = createBaseTransaction_Event_EntryAccepted();
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

function createBaseTransaction_Event_EntryRejected(): Transaction_Event_EntryRejected {
  return { createTime: undefined };
}

export const Transaction_Event_EntryRejected: MessageFns<Transaction_Event_EntryRejected> = {
  encode(message: Transaction_Event_EntryRejected, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_EntryRejected {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_EntryRejected();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_EntryRejected {
    return { createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined };
  },

  toJSON(message: Transaction_Event_EntryRejected): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_EntryRejected>, I>>(base?: I): Transaction_Event_EntryRejected {
    return Transaction_Event_EntryRejected.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_EntryRejected>, I>>(
    object: I,
  ): Transaction_Event_EntryRejected {
    const message = createBaseTransaction_Event_EntryRejected();
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

function createBaseTransaction_Event_Exited(): Transaction_Event_Exited {
  return {
    createTime: undefined,
    paymentProcessingResult: Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID,
  };
}

export const Transaction_Event_Exited: MessageFns<Transaction_Event_Exited> = {
  encode(message: Transaction_Event_Exited, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    if (message.paymentProcessingResult !== Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID) {
      writer.uint32(16).int32(transaction_PaymentProcessingResultToNumber(message.paymentProcessingResult));
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_Exited {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_Exited();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.paymentProcessingResult = transaction_PaymentProcessingResultFromJSON(reader.int32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_Exited {
    return {
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      paymentProcessingResult: isSet(object.paymentProcessingResult)
        ? transaction_PaymentProcessingResultFromJSON(object.paymentProcessingResult)
        : Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID,
    };
  },

  toJSON(message: Transaction_Event_Exited): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.paymentProcessingResult !== Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID) {
      obj.paymentProcessingResult = transaction_PaymentProcessingResultToJSON(message.paymentProcessingResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_Exited>, I>>(base?: I): Transaction_Event_Exited {
    return Transaction_Event_Exited.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_Exited>, I>>(object: I): Transaction_Event_Exited {
    const message = createBaseTransaction_Event_Exited();
    message.createTime = object.createTime ?? undefined;
    message.paymentProcessingResult = object.paymentProcessingResult ??
      Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID;
    return message;
  },
};

function createBaseTransaction_Event_Aborted(): Transaction_Event_Aborted {
  return { createTime: undefined };
}

export const Transaction_Event_Aborted: MessageFns<Transaction_Event_Aborted> = {
  encode(message: Transaction_Event_Aborted, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_Aborted {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_Aborted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_Aborted {
    return { createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined };
  },

  toJSON(message: Transaction_Event_Aborted): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_Aborted>, I>>(base?: I): Transaction_Event_Aborted {
    return Transaction_Event_Aborted.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_Aborted>, I>>(object: I): Transaction_Event_Aborted {
    const message = createBaseTransaction_Event_Aborted();
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

function createBaseTransaction_Event_Paid(): Transaction_Event_Paid {
  return { createTime: undefined };
}

export const Transaction_Event_Paid: MessageFns<Transaction_Event_Paid> = {
  encode(message: Transaction_Event_Paid, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Transaction_Event_Paid {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction_Event_Paid();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction_Event_Paid {
    return { createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined };
  },

  toJSON(message: Transaction_Event_Paid): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Transaction_Event_Paid>, I>>(base?: I): Transaction_Event_Paid {
    return Transaction_Event_Paid.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction_Event_Paid>, I>>(object: I): Transaction_Event_Paid {
    const message = createBaseTransaction_Event_Paid();
    message.createTime = object.createTime ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
