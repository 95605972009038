// Reference: https://github.com/gatsbyjs/gatsby-starter-blog/blob/master/src/components/seo.js
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React, { ReactNode } from "react"

type MetaProps = JSX.IntrinsicElements["meta"]

interface SEOProps {
  title: string
  description?: string
  meta?: MetaProps[]
  children?: ReactNode
}

export const SEO = ({ description, title, meta, children }: SEOProps) => {
  const { site } = useStaticQuery(graphql`
    query Site {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site?.siteMetadata?.title

  return (
    <>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
      />
      {meta?.map((m, i) => <meta key={i} {...m} />)}
      {children}
    </>
  )
}
