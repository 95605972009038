// extracted by mini-css-extract-plugin
export var checkboxRow = "parking-reservation-form-module--checkboxRow--b4bed";
export var container = "parking-reservation-form-module--container--04cb6";
export var dialogDescription = "parking-reservation-form-module--dialogDescription--af60d";
export var error = "parking-reservation-form-module--error--f80a8";
export var flexbox = "parking-reservation-form-module--flexbox--942c1";
export var footer = "parking-reservation-form-module--footer--bdae2";
export var handicappedSpaceCheckbox = "parking-reservation-form-module--handicappedSpaceCheckbox--8ad74";
export var importantNoticeText = "parking-reservation-form-module--importantNoticeText--7d14f";
export var infoKeySection = "parking-reservation-form-module--infoKeySection--6e220";
export var infoValueSection = "parking-reservation-form-module--infoValueSection--f5912";
export var licenseNumberInput = "parking-reservation-form-module--licenseNumberInput--8418e";
export var notice = "parking-reservation-form-module--notice--c8cf3";
export var reservationTimeTitle = "parking-reservation-form-module--reservationTimeTitle--79cb6";
export var spaceNumberInput = "parking-reservation-form-module--spaceNumberInput--4ec68";
export var textInput = "parking-reservation-form-module--textInput--b54bf";