export const SearchFilterType = {
  AVAILABLE: "available",
  WHEELCHAIR: "wheelchair",
  QT_NET_RESEVABLE: "qt_net_reservation",
  QT_NET_POINT: "qt_net_point",
  QT_NET_SMART_PAY: "qt_net_smart_pay",
} as const
type SearchFilterType = (typeof SearchFilterType)[keyof typeof SearchFilterType]

export function searchPCFilterText(type: keyof typeof SearchFilterType) {
  switch (type) {
    case "AVAILABLE":
      return "空車あり"
    case "WHEELCHAIR":
      return "車椅子対応あり"
    case "QT_NET_RESEVABLE":
      return "QT-net 予約可"
    case "QT_NET_POINT":
      return "QT-netポイントが貯まる"
    case "QT_NET_SMART_PAY":
      return "QT-net SmartPay利用可"
  }
}

export function searchMobileFilterText(type: keyof typeof SearchFilterType) {
  switch (type) {
    case "AVAILABLE":
      return "空車あり"
    case "WHEELCHAIR":
      return "車椅子"
    case "QT_NET_RESEVABLE":
      return "予約可"
    case "QT_NET_POINT":
      return "QT-net利用可"
    case "QT_NET_SMART_PAY":
      return "SmartPay利用可"
  }
}
