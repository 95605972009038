// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               unknown
// source: itech/motorist/pksha/v1/parking.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { LatLng } from "../../../../google/type/latlng";
import { Money } from "../../../../google/type/money";
import { TimeOfDay } from "../../../../google/type/timeofday";
import { ParkingBusinessOwner } from "./parking_business_owner";

export const protobufPackage = "itech.motorist.pksha.v1";

/** A representation of parking. */
export interface Parking {
  /**
   * The resource name for the parking.
   *
   * Format: parkings/{parking}
   */
  name: string;
  /** The display name of the parking. */
  displayName: string;
  /** The full address of the parking. */
  fullAddress: string;
  /** The type of the parking. */
  type: Parking_Type;
  /** The location of the parking. */
  latLng?:
    | LatLng
    | undefined;
  /** The current availability of the parking. */
  availability?:
    | ParkingAvailability
    | undefined;
  /**
   * The origin of the parking.
   *
   * NOTE: We really didn't want to expose the origin but raw id was required
   * for clients to interact with the legacy api.
   */
  origin?:
    | Parking_Origin
    | undefined;
  /**
   * If the parking is reservable. The reservation method may vary depends on
   * the parking's origin.
   */
  reservable: boolean;
  /** The fee table of the parking. */
  feeTable?:
    | FeeTable
    | undefined;
  /** The room numbers of the handicapped parking space. */
  handicappedSpaceNumbers: string[];
  /** If the parking is available to QT-net. */
  isQtNetAvailable: boolean;
  /** If the parking is available to SmartPay. */
  isSmartPayAvailable: boolean;
  /** The note of parking. */
  note: string;
  /** The researvable parking space numbers. */
  reservableSpaceNumbers: string[];
  /** The state of the parking go availability. */
  parkingGoState: Parking_ParkingGoState;
  /** If the parking is enabled mobile corporate card. */
  isMobileCorporateCardAvailable: boolean;
  /** A representation of the parking business owner. */
  parkingBusinessOwner?: ParkingBusinessOwner | undefined;
}

/** A type of parking. */
export enum Parking_Type {
  TYPE_INVALID = "TYPE_INVALID",
  /** TYPE_LOCK - The parking has flaps to lock each lot. */
  TYPE_LOCK = "TYPE_LOCK",
  /** TYPE_LOCKLESS - The parking has no flaps to lock. */
  TYPE_LOCKLESS = "TYPE_LOCKLESS",
  /** TYPE_GATE - The parking has a gate in the entrance. */
  TYPE_GATE = "TYPE_GATE",
  /**
   * TYPE_PHSLOCK - The parking has flaps to lock each lot and use PHS connection.
   * PHS type is legacy, so Itech is replacing it to LTE network.
   */
  TYPE_PHSLOCK = "TYPE_PHSLOCK",
}

export function parking_TypeFromJSON(object: any): Parking_Type {
  switch (object) {
    case 0:
    case "TYPE_INVALID":
      return Parking_Type.TYPE_INVALID;
    case 1:
    case "TYPE_LOCK":
      return Parking_Type.TYPE_LOCK;
    case 2:
    case "TYPE_LOCKLESS":
      return Parking_Type.TYPE_LOCKLESS;
    case 3:
    case "TYPE_GATE":
      return Parking_Type.TYPE_GATE;
    case 4:
    case "TYPE_PHSLOCK":
      return Parking_Type.TYPE_PHSLOCK;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Parking_Type");
  }
}

export function parking_TypeToJSON(object: Parking_Type): string {
  switch (object) {
    case Parking_Type.TYPE_INVALID:
      return "TYPE_INVALID";
    case Parking_Type.TYPE_LOCK:
      return "TYPE_LOCK";
    case Parking_Type.TYPE_LOCKLESS:
      return "TYPE_LOCKLESS";
    case Parking_Type.TYPE_GATE:
      return "TYPE_GATE";
    case Parking_Type.TYPE_PHSLOCK:
      return "TYPE_PHSLOCK";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Parking_Type");
  }
}

export function parking_TypeToNumber(object: Parking_Type): number {
  switch (object) {
    case Parking_Type.TYPE_INVALID:
      return 0;
    case Parking_Type.TYPE_LOCK:
      return 1;
    case Parking_Type.TYPE_LOCKLESS:
      return 2;
    case Parking_Type.TYPE_GATE:
      return 3;
    case Parking_Type.TYPE_PHSLOCK:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Parking_Type");
  }
}

/** The state whether parking go is available or not. */
export enum Parking_ParkingGoState {
  PARKING_GO_STATE_INVALID = "PARKING_GO_STATE_INVALID",
  /** PARKING_GO_STATE_UNAVAILABLE - ParkingGo is not available */
  PARKING_GO_STATE_UNAVAILABLE = "PARKING_GO_STATE_UNAVAILABLE",
  /** PARKING_GO_STATE_AVAILABLE - ParkingGo is available. */
  PARKING_GO_STATE_AVAILABLE = "PARKING_GO_STATE_AVAILABLE",
}

export function parking_ParkingGoStateFromJSON(object: any): Parking_ParkingGoState {
  switch (object) {
    case 0:
    case "PARKING_GO_STATE_INVALID":
      return Parking_ParkingGoState.PARKING_GO_STATE_INVALID;
    case 1:
    case "PARKING_GO_STATE_UNAVAILABLE":
      return Parking_ParkingGoState.PARKING_GO_STATE_UNAVAILABLE;
    case 2:
    case "PARKING_GO_STATE_AVAILABLE":
      return Parking_ParkingGoState.PARKING_GO_STATE_AVAILABLE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Parking_ParkingGoState");
  }
}

export function parking_ParkingGoStateToJSON(object: Parking_ParkingGoState): string {
  switch (object) {
    case Parking_ParkingGoState.PARKING_GO_STATE_INVALID:
      return "PARKING_GO_STATE_INVALID";
    case Parking_ParkingGoState.PARKING_GO_STATE_UNAVAILABLE:
      return "PARKING_GO_STATE_UNAVAILABLE";
    case Parking_ParkingGoState.PARKING_GO_STATE_AVAILABLE:
      return "PARKING_GO_STATE_AVAILABLE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Parking_ParkingGoState");
  }
}

export function parking_ParkingGoStateToNumber(object: Parking_ParkingGoState): number {
  switch (object) {
    case Parking_ParkingGoState.PARKING_GO_STATE_INVALID:
      return 0;
    case Parking_ParkingGoState.PARKING_GO_STATE_UNAVAILABLE:
      return 1;
    case Parking_ParkingGoState.PARKING_GO_STATE_AVAILABLE:
      return 2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Parking_ParkingGoState");
  }
}

/** A (supplier, raw_id) tuple that identifies imported parking origin. */
export interface Parking_Origin {
  /**
   * The supplier of a parking data, typically a company identifier such as
   * itech or peasy.
   */
  supplier: string;
  /** The parking raw id that is used by the supplier. */
  rawId: string;
}

/** A representation of parking's availability such as number parked cars. */
export interface ParkingAvailability {
  /** The total number of parking spaces. */
  totalSpaceCount: number;
  /** The number of parking spaces that is in use. */
  inUseSpaceCount: number;
  /** The availability summary. */
  summary: ParkingAvailability_Summary;
}

/** Summary of availability. */
export enum ParkingAvailability_Summary {
  SUMMARY_INVALID = "SUMMARY_INVALID",
  /** SUMMARY_EMPTY - Many spaces are available. */
  SUMMARY_EMPTY = "SUMMARY_EMPTY",
  /** SUMMARY_CROWDED - Many spaces are in use. */
  SUMMARY_CROWDED = "SUMMARY_CROWDED",
  /** SUMMARY_FULL - All spaces are in use. */
  SUMMARY_FULL = "SUMMARY_FULL",
  /** SUMMARY_UNOBTAINABLE - The availability is unobtainable when Type is TYPE_PHSLOCK. */
  SUMMARY_UNOBTAINABLE = "SUMMARY_UNOBTAINABLE",
}

export function parkingAvailability_SummaryFromJSON(object: any): ParkingAvailability_Summary {
  switch (object) {
    case 0:
    case "SUMMARY_INVALID":
      return ParkingAvailability_Summary.SUMMARY_INVALID;
    case 1:
    case "SUMMARY_EMPTY":
      return ParkingAvailability_Summary.SUMMARY_EMPTY;
    case 2:
    case "SUMMARY_CROWDED":
      return ParkingAvailability_Summary.SUMMARY_CROWDED;
    case 3:
    case "SUMMARY_FULL":
      return ParkingAvailability_Summary.SUMMARY_FULL;
    case 4:
    case "SUMMARY_UNOBTAINABLE":
      return ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ParkingAvailability_Summary");
  }
}

export function parkingAvailability_SummaryToJSON(object: ParkingAvailability_Summary): string {
  switch (object) {
    case ParkingAvailability_Summary.SUMMARY_INVALID:
      return "SUMMARY_INVALID";
    case ParkingAvailability_Summary.SUMMARY_EMPTY:
      return "SUMMARY_EMPTY";
    case ParkingAvailability_Summary.SUMMARY_CROWDED:
      return "SUMMARY_CROWDED";
    case ParkingAvailability_Summary.SUMMARY_FULL:
      return "SUMMARY_FULL";
    case ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE:
      return "SUMMARY_UNOBTAINABLE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ParkingAvailability_Summary");
  }
}

export function parkingAvailability_SummaryToNumber(object: ParkingAvailability_Summary): number {
  switch (object) {
    case ParkingAvailability_Summary.SUMMARY_INVALID:
      return 0;
    case ParkingAvailability_Summary.SUMMARY_EMPTY:
      return 1;
    case ParkingAvailability_Summary.SUMMARY_CROWDED:
      return 2;
    case ParkingAvailability_Summary.SUMMARY_FULL:
      return 3;
    case ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE:
      return 4;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ParkingAvailability_Summary");
  }
}

/** A representation of daily parking's fee list. */
export interface FeeTable {
  /** The fee list for weekday. */
  weekdayFees: FeeUnit[];
  /** The fee list for saturday. */
  saturdayFees: FeeUnit[];
  /** The fee list for sunday. */
  sundayFees: FeeUnit[];
  /** The fee list for holiday. */
  holidayFees: FeeUnit[];
  /** The max fee infomation for weekday. */
  weekdayMaxFee?:
    | SubMaxFee
    | undefined;
  /** The max fee infomation for saturday. */
  saturdayMaxFee?:
    | SubMaxFee
    | undefined;
  /** The max fee infomation for sunday. */
  sundayMaxFee?:
    | SubMaxFee
    | undefined;
  /** The max fee infomation for holiday. */
  holidayMaxFee?: SubMaxFee | undefined;
}

/** A representation of parking's pricing by time of day. */
export interface FeeUnit {
  /**
   * The starting time of the time period that this pricing is applied to. This
   * start_time is inclusive value. [start_time, end_time)
   */
  startTime?:
    | TimeOfDay
    | undefined;
  /**
   * The end time of the time period that this pricing is applied to. This
   * end_time is exlusive value (half-closed intervals). [start_time, end_time)
   */
  endTime?:
    | TimeOfDay
    | undefined;
  /**
   * The Unit duration to be charged for the time period that this pricing is
   * applied to.
   */
  unitDuration: string;
  /**
   * The fee per unit_duration of the time period that this pricing is applied
   * to. Fees are calculated by rounding up the time of day.
   */
  feePerUnitDuration?:
    | Money
    | undefined;
  /** The maximum fee of the time period that this pricing is applied to. */
  maxFee?: Money | undefined;
}

/** A representation of parking's max fee that does not belong to a FeeUnit. */
export interface SubMaxFee {
  /**
   * The hourly max fee is not part of FeeUnit and represents the
   * max fee per time_specified_max_fee_duration in a day.
   * This value is applied when MaxFee in FeeUnit is disabled (or not set)
   * in Itech's pricing system.
   */
  hourly?:
    | HourlyMaxFee
    | undefined;
  /**
   * The daily max fee is max fee per day.
   * This value is applied when MaxFee in FeeUnit is disabled (or not set)
   * and hourly_max_fee is disabled (or not set) in Itech's pricing
   * system.
   */
  daily?: Money | undefined;
}

/**
 * A representation of parking's hourly max fee that does not belong to a
 * FeeUnit.
 */
export interface HourlyMaxFee {
  /** The fee per hour is max fee per hour duration. */
  feePerHour?:
    | Money
    | undefined;
  /** The hour duration which hourly_max_fee is applied. */
  hourDuration: string;
}

function createBaseParking(): Parking {
  return {
    name: "",
    displayName: "",
    fullAddress: "",
    type: Parking_Type.TYPE_INVALID,
    latLng: undefined,
    availability: undefined,
    origin: undefined,
    reservable: false,
    feeTable: undefined,
    handicappedSpaceNumbers: [],
    isQtNetAvailable: false,
    isSmartPayAvailable: false,
    note: "",
    reservableSpaceNumbers: [],
    parkingGoState: Parking_ParkingGoState.PARKING_GO_STATE_INVALID,
    isMobileCorporateCardAvailable: false,
    parkingBusinessOwner: undefined,
  };
}

export const Parking: MessageFns<Parking> = {
  encode(message: Parking, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.fullAddress !== "") {
      writer.uint32(26).string(message.fullAddress);
    }
    if (message.type !== Parking_Type.TYPE_INVALID) {
      writer.uint32(32).int32(parking_TypeToNumber(message.type));
    }
    if (message.latLng !== undefined) {
      LatLng.encode(message.latLng, writer.uint32(42).fork()).join();
    }
    if (message.availability !== undefined) {
      ParkingAvailability.encode(message.availability, writer.uint32(50).fork()).join();
    }
    if (message.origin !== undefined) {
      Parking_Origin.encode(message.origin, writer.uint32(58).fork()).join();
    }
    if (message.reservable !== false) {
      writer.uint32(64).bool(message.reservable);
    }
    if (message.feeTable !== undefined) {
      FeeTable.encode(message.feeTable, writer.uint32(74).fork()).join();
    }
    for (const v of message.handicappedSpaceNumbers) {
      writer.uint32(82).string(v!);
    }
    if (message.isQtNetAvailable !== false) {
      writer.uint32(88).bool(message.isQtNetAvailable);
    }
    if (message.isSmartPayAvailable !== false) {
      writer.uint32(96).bool(message.isSmartPayAvailable);
    }
    if (message.note !== "") {
      writer.uint32(106).string(message.note);
    }
    for (const v of message.reservableSpaceNumbers) {
      writer.uint32(114).string(v!);
    }
    if (message.parkingGoState !== Parking_ParkingGoState.PARKING_GO_STATE_INVALID) {
      writer.uint32(120).int32(parking_ParkingGoStateToNumber(message.parkingGoState));
    }
    if (message.isMobileCorporateCardAvailable !== false) {
      writer.uint32(128).bool(message.isMobileCorporateCardAvailable);
    }
    if (message.parkingBusinessOwner !== undefined) {
      ParkingBusinessOwner.encode(message.parkingBusinessOwner, writer.uint32(146).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Parking {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParking();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.fullAddress = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.type = parking_TypeFromJSON(reader.int32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.latLng = LatLng.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.availability = ParkingAvailability.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.origin = Parking_Origin.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.reservable = reader.bool();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.feeTable = FeeTable.decode(reader, reader.uint32());
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.handicappedSpaceNumbers.push(reader.string());
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.isQtNetAvailable = reader.bool();
          continue;
        }
        case 12: {
          if (tag !== 96) {
            break;
          }

          message.isSmartPayAvailable = reader.bool();
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.note = reader.string();
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.reservableSpaceNumbers.push(reader.string());
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.parkingGoState = parking_ParkingGoStateFromJSON(reader.int32());
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.isMobileCorporateCardAvailable = reader.bool();
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.parkingBusinessOwner = ParkingBusinessOwner.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Parking {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      fullAddress: isSet(object.fullAddress) ? globalThis.String(object.fullAddress) : "",
      type: isSet(object.type) ? parking_TypeFromJSON(object.type) : Parking_Type.TYPE_INVALID,
      latLng: isSet(object.latLng) ? LatLng.fromJSON(object.latLng) : undefined,
      availability: isSet(object.availability) ? ParkingAvailability.fromJSON(object.availability) : undefined,
      origin: isSet(object.origin) ? Parking_Origin.fromJSON(object.origin) : undefined,
      reservable: isSet(object.reservable) ? globalThis.Boolean(object.reservable) : false,
      feeTable: isSet(object.feeTable) ? FeeTable.fromJSON(object.feeTable) : undefined,
      handicappedSpaceNumbers: globalThis.Array.isArray(object?.handicappedSpaceNumbers)
        ? object.handicappedSpaceNumbers.map((e: any) => globalThis.String(e))
        : [],
      isQtNetAvailable: isSet(object.isQtNetAvailable) ? globalThis.Boolean(object.isQtNetAvailable) : false,
      isSmartPayAvailable: isSet(object.isSmartPayAvailable) ? globalThis.Boolean(object.isSmartPayAvailable) : false,
      note: isSet(object.note) ? globalThis.String(object.note) : "",
      reservableSpaceNumbers: globalThis.Array.isArray(object?.reservableSpaceNumbers)
        ? object.reservableSpaceNumbers.map((e: any) => globalThis.String(e))
        : [],
      parkingGoState: isSet(object.parkingGoState)
        ? parking_ParkingGoStateFromJSON(object.parkingGoState)
        : Parking_ParkingGoState.PARKING_GO_STATE_INVALID,
      isMobileCorporateCardAvailable: isSet(object.isMobileCorporateCardAvailable)
        ? globalThis.Boolean(object.isMobileCorporateCardAvailable)
        : false,
      parkingBusinessOwner: isSet(object.parkingBusinessOwner)
        ? ParkingBusinessOwner.fromJSON(object.parkingBusinessOwner)
        : undefined,
    };
  },

  toJSON(message: Parking): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.fullAddress !== "") {
      obj.fullAddress = message.fullAddress;
    }
    if (message.type !== Parking_Type.TYPE_INVALID) {
      obj.type = parking_TypeToJSON(message.type);
    }
    if (message.latLng !== undefined) {
      obj.latLng = LatLng.toJSON(message.latLng);
    }
    if (message.availability !== undefined) {
      obj.availability = ParkingAvailability.toJSON(message.availability);
    }
    if (message.origin !== undefined) {
      obj.origin = Parking_Origin.toJSON(message.origin);
    }
    if (message.reservable !== false) {
      obj.reservable = message.reservable;
    }
    if (message.feeTable !== undefined) {
      obj.feeTable = FeeTable.toJSON(message.feeTable);
    }
    if (message.handicappedSpaceNumbers?.length) {
      obj.handicappedSpaceNumbers = message.handicappedSpaceNumbers;
    }
    if (message.isQtNetAvailable !== false) {
      obj.isQtNetAvailable = message.isQtNetAvailable;
    }
    if (message.isSmartPayAvailable !== false) {
      obj.isSmartPayAvailable = message.isSmartPayAvailable;
    }
    if (message.note !== "") {
      obj.note = message.note;
    }
    if (message.reservableSpaceNumbers?.length) {
      obj.reservableSpaceNumbers = message.reservableSpaceNumbers;
    }
    if (message.parkingGoState !== Parking_ParkingGoState.PARKING_GO_STATE_INVALID) {
      obj.parkingGoState = parking_ParkingGoStateToJSON(message.parkingGoState);
    }
    if (message.isMobileCorporateCardAvailable !== false) {
      obj.isMobileCorporateCardAvailable = message.isMobileCorporateCardAvailable;
    }
    if (message.parkingBusinessOwner !== undefined) {
      obj.parkingBusinessOwner = ParkingBusinessOwner.toJSON(message.parkingBusinessOwner);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Parking>, I>>(base?: I): Parking {
    return Parking.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Parking>, I>>(object: I): Parking {
    const message = createBaseParking();
    message.name = object.name ?? "";
    message.displayName = object.displayName ?? "";
    message.fullAddress = object.fullAddress ?? "";
    message.type = object.type ?? Parking_Type.TYPE_INVALID;
    message.latLng = (object.latLng !== undefined && object.latLng !== null)
      ? LatLng.fromPartial(object.latLng)
      : undefined;
    message.availability = (object.availability !== undefined && object.availability !== null)
      ? ParkingAvailability.fromPartial(object.availability)
      : undefined;
    message.origin = (object.origin !== undefined && object.origin !== null)
      ? Parking_Origin.fromPartial(object.origin)
      : undefined;
    message.reservable = object.reservable ?? false;
    message.feeTable = (object.feeTable !== undefined && object.feeTable !== null)
      ? FeeTable.fromPartial(object.feeTable)
      : undefined;
    message.handicappedSpaceNumbers = object.handicappedSpaceNumbers?.map((e) => e) || [];
    message.isQtNetAvailable = object.isQtNetAvailable ?? false;
    message.isSmartPayAvailable = object.isSmartPayAvailable ?? false;
    message.note = object.note ?? "";
    message.reservableSpaceNumbers = object.reservableSpaceNumbers?.map((e) => e) || [];
    message.parkingGoState = object.parkingGoState ?? Parking_ParkingGoState.PARKING_GO_STATE_INVALID;
    message.isMobileCorporateCardAvailable = object.isMobileCorporateCardAvailable ?? false;
    message.parkingBusinessOwner = (object.parkingBusinessOwner !== undefined && object.parkingBusinessOwner !== null)
      ? ParkingBusinessOwner.fromPartial(object.parkingBusinessOwner)
      : undefined;
    return message;
  },
};

function createBaseParking_Origin(): Parking_Origin {
  return { supplier: "", rawId: "" };
}

export const Parking_Origin: MessageFns<Parking_Origin> = {
  encode(message: Parking_Origin, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.supplier !== "") {
      writer.uint32(10).string(message.supplier);
    }
    if (message.rawId !== "") {
      writer.uint32(18).string(message.rawId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Parking_Origin {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParking_Origin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.supplier = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rawId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Parking_Origin {
    return {
      supplier: isSet(object.supplier) ? globalThis.String(object.supplier) : "",
      rawId: isSet(object.rawId) ? globalThis.String(object.rawId) : "",
    };
  },

  toJSON(message: Parking_Origin): unknown {
    const obj: any = {};
    if (message.supplier !== "") {
      obj.supplier = message.supplier;
    }
    if (message.rawId !== "") {
      obj.rawId = message.rawId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Parking_Origin>, I>>(base?: I): Parking_Origin {
    return Parking_Origin.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Parking_Origin>, I>>(object: I): Parking_Origin {
    const message = createBaseParking_Origin();
    message.supplier = object.supplier ?? "";
    message.rawId = object.rawId ?? "";
    return message;
  },
};

function createBaseParkingAvailability(): ParkingAvailability {
  return { totalSpaceCount: 0, inUseSpaceCount: 0, summary: ParkingAvailability_Summary.SUMMARY_INVALID };
}

export const ParkingAvailability: MessageFns<ParkingAvailability> = {
  encode(message: ParkingAvailability, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.totalSpaceCount !== 0) {
      writer.uint32(8).int32(message.totalSpaceCount);
    }
    if (message.inUseSpaceCount !== 0) {
      writer.uint32(16).int32(message.inUseSpaceCount);
    }
    if (message.summary !== ParkingAvailability_Summary.SUMMARY_INVALID) {
      writer.uint32(24).int32(parkingAvailability_SummaryToNumber(message.summary));
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ParkingAvailability {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParkingAvailability();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.totalSpaceCount = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.inUseSpaceCount = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.summary = parkingAvailability_SummaryFromJSON(reader.int32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ParkingAvailability {
    return {
      totalSpaceCount: isSet(object.totalSpaceCount) ? globalThis.Number(object.totalSpaceCount) : 0,
      inUseSpaceCount: isSet(object.inUseSpaceCount) ? globalThis.Number(object.inUseSpaceCount) : 0,
      summary: isSet(object.summary)
        ? parkingAvailability_SummaryFromJSON(object.summary)
        : ParkingAvailability_Summary.SUMMARY_INVALID,
    };
  },

  toJSON(message: ParkingAvailability): unknown {
    const obj: any = {};
    if (message.totalSpaceCount !== 0) {
      obj.totalSpaceCount = Math.round(message.totalSpaceCount);
    }
    if (message.inUseSpaceCount !== 0) {
      obj.inUseSpaceCount = Math.round(message.inUseSpaceCount);
    }
    if (message.summary !== ParkingAvailability_Summary.SUMMARY_INVALID) {
      obj.summary = parkingAvailability_SummaryToJSON(message.summary);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ParkingAvailability>, I>>(base?: I): ParkingAvailability {
    return ParkingAvailability.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ParkingAvailability>, I>>(object: I): ParkingAvailability {
    const message = createBaseParkingAvailability();
    message.totalSpaceCount = object.totalSpaceCount ?? 0;
    message.inUseSpaceCount = object.inUseSpaceCount ?? 0;
    message.summary = object.summary ?? ParkingAvailability_Summary.SUMMARY_INVALID;
    return message;
  },
};

function createBaseFeeTable(): FeeTable {
  return {
    weekdayFees: [],
    saturdayFees: [],
    sundayFees: [],
    holidayFees: [],
    weekdayMaxFee: undefined,
    saturdayMaxFee: undefined,
    sundayMaxFee: undefined,
    holidayMaxFee: undefined,
  };
}

export const FeeTable: MessageFns<FeeTable> = {
  encode(message: FeeTable, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.weekdayFees) {
      FeeUnit.encode(v!, writer.uint32(10).fork()).join();
    }
    for (const v of message.saturdayFees) {
      FeeUnit.encode(v!, writer.uint32(18).fork()).join();
    }
    for (const v of message.sundayFees) {
      FeeUnit.encode(v!, writer.uint32(26).fork()).join();
    }
    for (const v of message.holidayFees) {
      FeeUnit.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.weekdayMaxFee !== undefined) {
      SubMaxFee.encode(message.weekdayMaxFee, writer.uint32(42).fork()).join();
    }
    if (message.saturdayMaxFee !== undefined) {
      SubMaxFee.encode(message.saturdayMaxFee, writer.uint32(50).fork()).join();
    }
    if (message.sundayMaxFee !== undefined) {
      SubMaxFee.encode(message.sundayMaxFee, writer.uint32(58).fork()).join();
    }
    if (message.holidayMaxFee !== undefined) {
      SubMaxFee.encode(message.holidayMaxFee, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FeeTable {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeeTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.weekdayFees.push(FeeUnit.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.saturdayFees.push(FeeUnit.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.sundayFees.push(FeeUnit.decode(reader, reader.uint32()));
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.holidayFees.push(FeeUnit.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.weekdayMaxFee = SubMaxFee.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.saturdayMaxFee = SubMaxFee.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.sundayMaxFee = SubMaxFee.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.holidayMaxFee = SubMaxFee.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeeTable {
    return {
      weekdayFees: globalThis.Array.isArray(object?.weekdayFees)
        ? object.weekdayFees.map((e: any) => FeeUnit.fromJSON(e))
        : [],
      saturdayFees: globalThis.Array.isArray(object?.saturdayFees)
        ? object.saturdayFees.map((e: any) => FeeUnit.fromJSON(e))
        : [],
      sundayFees: globalThis.Array.isArray(object?.sundayFees)
        ? object.sundayFees.map((e: any) => FeeUnit.fromJSON(e))
        : [],
      holidayFees: globalThis.Array.isArray(object?.holidayFees)
        ? object.holidayFees.map((e: any) => FeeUnit.fromJSON(e))
        : [],
      weekdayMaxFee: isSet(object.weekdayMaxFee) ? SubMaxFee.fromJSON(object.weekdayMaxFee) : undefined,
      saturdayMaxFee: isSet(object.saturdayMaxFee) ? SubMaxFee.fromJSON(object.saturdayMaxFee) : undefined,
      sundayMaxFee: isSet(object.sundayMaxFee) ? SubMaxFee.fromJSON(object.sundayMaxFee) : undefined,
      holidayMaxFee: isSet(object.holidayMaxFee) ? SubMaxFee.fromJSON(object.holidayMaxFee) : undefined,
    };
  },

  toJSON(message: FeeTable): unknown {
    const obj: any = {};
    if (message.weekdayFees?.length) {
      obj.weekdayFees = message.weekdayFees.map((e) => FeeUnit.toJSON(e));
    }
    if (message.saturdayFees?.length) {
      obj.saturdayFees = message.saturdayFees.map((e) => FeeUnit.toJSON(e));
    }
    if (message.sundayFees?.length) {
      obj.sundayFees = message.sundayFees.map((e) => FeeUnit.toJSON(e));
    }
    if (message.holidayFees?.length) {
      obj.holidayFees = message.holidayFees.map((e) => FeeUnit.toJSON(e));
    }
    if (message.weekdayMaxFee !== undefined) {
      obj.weekdayMaxFee = SubMaxFee.toJSON(message.weekdayMaxFee);
    }
    if (message.saturdayMaxFee !== undefined) {
      obj.saturdayMaxFee = SubMaxFee.toJSON(message.saturdayMaxFee);
    }
    if (message.sundayMaxFee !== undefined) {
      obj.sundayMaxFee = SubMaxFee.toJSON(message.sundayMaxFee);
    }
    if (message.holidayMaxFee !== undefined) {
      obj.holidayMaxFee = SubMaxFee.toJSON(message.holidayMaxFee);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeeTable>, I>>(base?: I): FeeTable {
    return FeeTable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeeTable>, I>>(object: I): FeeTable {
    const message = createBaseFeeTable();
    message.weekdayFees = object.weekdayFees?.map((e) => FeeUnit.fromPartial(e)) || [];
    message.saturdayFees = object.saturdayFees?.map((e) => FeeUnit.fromPartial(e)) || [];
    message.sundayFees = object.sundayFees?.map((e) => FeeUnit.fromPartial(e)) || [];
    message.holidayFees = object.holidayFees?.map((e) => FeeUnit.fromPartial(e)) || [];
    message.weekdayMaxFee = (object.weekdayMaxFee !== undefined && object.weekdayMaxFee !== null)
      ? SubMaxFee.fromPartial(object.weekdayMaxFee)
      : undefined;
    message.saturdayMaxFee = (object.saturdayMaxFee !== undefined && object.saturdayMaxFee !== null)
      ? SubMaxFee.fromPartial(object.saturdayMaxFee)
      : undefined;
    message.sundayMaxFee = (object.sundayMaxFee !== undefined && object.sundayMaxFee !== null)
      ? SubMaxFee.fromPartial(object.sundayMaxFee)
      : undefined;
    message.holidayMaxFee = (object.holidayMaxFee !== undefined && object.holidayMaxFee !== null)
      ? SubMaxFee.fromPartial(object.holidayMaxFee)
      : undefined;
    return message;
  },
};

function createBaseFeeUnit(): FeeUnit {
  return {
    startTime: undefined,
    endTime: undefined,
    unitDuration: "",
    feePerUnitDuration: undefined,
    maxFee: undefined,
  };
}

export const FeeUnit: MessageFns<FeeUnit> = {
  encode(message: FeeUnit, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.startTime !== undefined) {
      TimeOfDay.encode(message.startTime, writer.uint32(10).fork()).join();
    }
    if (message.endTime !== undefined) {
      TimeOfDay.encode(message.endTime, writer.uint32(18).fork()).join();
    }
    if (message.unitDuration !== "") {
      writer.uint32(26).string(message.unitDuration);
    }
    if (message.feePerUnitDuration !== undefined) {
      Money.encode(message.feePerUnitDuration, writer.uint32(34).fork()).join();
    }
    if (message.maxFee !== undefined) {
      Money.encode(message.maxFee, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FeeUnit {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeeUnit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.startTime = TimeOfDay.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.endTime = TimeOfDay.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.unitDuration = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.feePerUnitDuration = Money.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.maxFee = Money.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeeUnit {
    return {
      startTime: isSet(object.startTime) ? TimeOfDay.fromJSON(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? TimeOfDay.fromJSON(object.endTime) : undefined,
      unitDuration: isSet(object.unitDuration) ? globalThis.String(object.unitDuration) : "",
      feePerUnitDuration: isSet(object.feePerUnitDuration) ? Money.fromJSON(object.feePerUnitDuration) : undefined,
      maxFee: isSet(object.maxFee) ? Money.fromJSON(object.maxFee) : undefined,
    };
  },

  toJSON(message: FeeUnit): unknown {
    const obj: any = {};
    if (message.startTime !== undefined) {
      obj.startTime = TimeOfDay.toJSON(message.startTime);
    }
    if (message.endTime !== undefined) {
      obj.endTime = TimeOfDay.toJSON(message.endTime);
    }
    if (message.unitDuration !== "") {
      obj.unitDuration = message.unitDuration;
    }
    if (message.feePerUnitDuration !== undefined) {
      obj.feePerUnitDuration = Money.toJSON(message.feePerUnitDuration);
    }
    if (message.maxFee !== undefined) {
      obj.maxFee = Money.toJSON(message.maxFee);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeeUnit>, I>>(base?: I): FeeUnit {
    return FeeUnit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeeUnit>, I>>(object: I): FeeUnit {
    const message = createBaseFeeUnit();
    message.startTime = (object.startTime !== undefined && object.startTime !== null)
      ? TimeOfDay.fromPartial(object.startTime)
      : undefined;
    message.endTime = (object.endTime !== undefined && object.endTime !== null)
      ? TimeOfDay.fromPartial(object.endTime)
      : undefined;
    message.unitDuration = object.unitDuration ?? "";
    message.feePerUnitDuration = (object.feePerUnitDuration !== undefined && object.feePerUnitDuration !== null)
      ? Money.fromPartial(object.feePerUnitDuration)
      : undefined;
    message.maxFee = (object.maxFee !== undefined && object.maxFee !== null)
      ? Money.fromPartial(object.maxFee)
      : undefined;
    return message;
  },
};

function createBaseSubMaxFee(): SubMaxFee {
  return { hourly: undefined, daily: undefined };
}

export const SubMaxFee: MessageFns<SubMaxFee> = {
  encode(message: SubMaxFee, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hourly !== undefined) {
      HourlyMaxFee.encode(message.hourly, writer.uint32(10).fork()).join();
    }
    if (message.daily !== undefined) {
      Money.encode(message.daily, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SubMaxFee {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubMaxFee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hourly = HourlyMaxFee.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.daily = Money.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubMaxFee {
    return {
      hourly: isSet(object.hourly) ? HourlyMaxFee.fromJSON(object.hourly) : undefined,
      daily: isSet(object.daily) ? Money.fromJSON(object.daily) : undefined,
    };
  },

  toJSON(message: SubMaxFee): unknown {
    const obj: any = {};
    if (message.hourly !== undefined) {
      obj.hourly = HourlyMaxFee.toJSON(message.hourly);
    }
    if (message.daily !== undefined) {
      obj.daily = Money.toJSON(message.daily);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubMaxFee>, I>>(base?: I): SubMaxFee {
    return SubMaxFee.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubMaxFee>, I>>(object: I): SubMaxFee {
    const message = createBaseSubMaxFee();
    message.hourly = (object.hourly !== undefined && object.hourly !== null)
      ? HourlyMaxFee.fromPartial(object.hourly)
      : undefined;
    message.daily = (object.daily !== undefined && object.daily !== null) ? Money.fromPartial(object.daily) : undefined;
    return message;
  },
};

function createBaseHourlyMaxFee(): HourlyMaxFee {
  return { feePerHour: undefined, hourDuration: "" };
}

export const HourlyMaxFee: MessageFns<HourlyMaxFee> = {
  encode(message: HourlyMaxFee, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.feePerHour !== undefined) {
      Money.encode(message.feePerHour, writer.uint32(10).fork()).join();
    }
    if (message.hourDuration !== "") {
      writer.uint32(18).string(message.hourDuration);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HourlyMaxFee {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHourlyMaxFee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.feePerHour = Money.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hourDuration = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HourlyMaxFee {
    return {
      feePerHour: isSet(object.feePerHour) ? Money.fromJSON(object.feePerHour) : undefined,
      hourDuration: isSet(object.hourDuration) ? globalThis.String(object.hourDuration) : "",
    };
  },

  toJSON(message: HourlyMaxFee): unknown {
    const obj: any = {};
    if (message.feePerHour !== undefined) {
      obj.feePerHour = Money.toJSON(message.feePerHour);
    }
    if (message.hourDuration !== "") {
      obj.hourDuration = message.hourDuration;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HourlyMaxFee>, I>>(base?: I): HourlyMaxFee {
    return HourlyMaxFee.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HourlyMaxFee>, I>>(object: I): HourlyMaxFee {
    const message = createBaseHourlyMaxFee();
    message.feePerHour = (object.feePerHour !== undefined && object.feePerHour !== null)
      ? Money.fromPartial(object.feePerHour)
      : undefined;
    message.hourDuration = object.hourDuration ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
