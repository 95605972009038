// extracted by mini-css-extract-plugin
export var commonTextStyle = "header-module--commonTextStyle--95c04";
export var dropdownItem = "header-module--dropdownItem--f25d5";
export var dropdownMenu = "header-module--dropdownMenu--0b312";
export var header = "header-module--header--8bd75";
export var logo = "header-module--logo--99006";
export var menu = "header-module--menu--deefa";
export var menuItem = "header-module--menuItem--f6dec";
export var row = "header-module--row--0b098";
export var serviceName = "header-module--serviceName--4fb39";
export var title = "header-module--title--f786a";
export var userInfo = "header-module--userInfo--2d4c5";
export var userSection = "header-module--userSection--c95df";