/**
 * Gatsby Browser APIs.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 *
 * Note: The APIs wrapPageElement and wrapRootElement exist in both the browser and Server-Side Rendering (SSR) APIs.
 * You generally should implement the same components in both gatsby-ssr.js and gatsby-browser.js so that pages generated through SSR
 * are the same after being hydrated in the browser.
 */

import type { GatsbyBrowser } from "gatsby"
import React from "react"
import { Layout } from "./src/components"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => <Layout>{element}</Layout>
