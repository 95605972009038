// extracted by mini-css-extract-plugin
export var addressText = "parking-detail-info-row-module--addressText--b4b25";
export var container = "parking-detail-info-row-module--container--45699";
export var copyAddressButton = "parking-detail-info-row-module--copyAddressButton--9e888";
export var downloadQtNetButton = "parking-detail-info-row-module--downloadQtNetButton--68657";
export var feesRow = "parking-detail-info-row-module--feesRow--fd993";
export var feesText = "parking-detail-info-row-module--feesText--ad6a6";
export var infoKeySection = "parking-detail-info-row-module--infoKeySection--1647d";
export var infoRow = "parking-detail-info-row-module--infoRow--7e686";
export var infoValueSection = "parking-detail-info-row-module--infoValueSection--9e4a5";
export var openGoogleMapButton = "parking-detail-info-row-module--openGoogleMapButton--f960a";
export var servicesText = "parking-detail-info-row-module--servicesText--df218";