import React, { ReactElement, ReactNode } from "react"
import MediaQuery from "react-responsive"
import * as config from "../../config"

export const UniversalMediaQuery = (props: {
  children: ReactNode
  minWidth?: number
  maxWidth?: number
}): ReactElement => {
  const isSSR = typeof window === "undefined"
  const serverDevice = { width: config.RESPONSIVE_BREAKPOINT + 1 }
  const matches =
    (props.minWidth == null || serverDevice.width >= props.minWidth) &&
    (props.maxWidth == null || serverDevice.width <= props.maxWidth)

  if (isSSR) {
    return matches ? <>{props.children}</> : <></>
  }

  return (
    <MediaQuery minWidth={props.minWidth} maxWidth={props.maxWidth}>
      {props.children}
    </MediaQuery>
  )
}
