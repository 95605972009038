// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               unknown
// source: itech/motorist/pksha/v1/parking_business_owner.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { DateMessage } from "../../../../google/type/date";

export const protobufPackage = "itech.motorist.pksha.v1";

/** A representation of the parking business owner. */
export interface ParkingBusinessOwner {
  /**
   * Unique identifier for the receipt issuing, representing its registered
   * number.
   */
  registrationNumber: string;
  /** Name of parking bussiness owner. */
  displayName: string;
  /**
   * active date is the date the invoice registration number is displayed on the
   * receipt.
   */
  activeDate?: DateMessage | undefined;
}

function createBaseParkingBusinessOwner(): ParkingBusinessOwner {
  return { registrationNumber: "", displayName: "", activeDate: undefined };
}

export const ParkingBusinessOwner: MessageFns<ParkingBusinessOwner> = {
  encode(message: ParkingBusinessOwner, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.registrationNumber !== "") {
      writer.uint32(10).string(message.registrationNumber);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.activeDate !== undefined) {
      DateMessage.encode(message.activeDate, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ParkingBusinessOwner {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParkingBusinessOwner();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.registrationNumber = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.activeDate = DateMessage.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ParkingBusinessOwner {
    return {
      registrationNumber: isSet(object.registrationNumber) ? globalThis.String(object.registrationNumber) : "",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      activeDate: isSet(object.activeDate) ? DateMessage.fromJSON(object.activeDate) : undefined,
    };
  },

  toJSON(message: ParkingBusinessOwner): unknown {
    const obj: any = {};
    if (message.registrationNumber !== "") {
      obj.registrationNumber = message.registrationNumber;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.activeDate !== undefined) {
      obj.activeDate = DateMessage.toJSON(message.activeDate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ParkingBusinessOwner>, I>>(base?: I): ParkingBusinessOwner {
    return ParkingBusinessOwner.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ParkingBusinessOwner>, I>>(object: I): ParkingBusinessOwner {
    const message = createBaseParkingBusinessOwner();
    message.registrationNumber = object.registrationNumber ?? "";
    message.displayName = object.displayName ?? "";
    message.activeDate = (object.activeDate !== undefined && object.activeDate !== null)
      ? DateMessage.fromPartial(object.activeDate)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
