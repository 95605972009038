// extracted by mini-css-extract-plugin
export var commonTextStyle = "mobile-search-header-module--commonTextStyle--545c8";
export var dropdownItem = "mobile-search-header-module--dropdownItem--8e08f";
export var dropdownMenu = "mobile-search-header-module--dropdownMenu--b5ac3";
export var header = "mobile-search-header-module--header--50cb2";
export var lowerRow = "mobile-search-header-module--lowerRow--98954";
export var menu = "mobile-search-header-module--menu--5cc5f";
export var menuItem = "mobile-search-header-module--menuItem--51a39";
export var searchInput = "mobile-search-header-module--searchInput--3cb89";
export var serviceName = "mobile-search-header-module--serviceName--650d6";
export var upperRow = "mobile-search-header-module--upperRow--08626";
export var userInfo = "mobile-search-header-module--userInfo--0a193";
export var userSection = "mobile-search-header-module--userSection--1894a";