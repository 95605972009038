import React, { ReactElement } from "react"
import { useMediaQuery } from "react-responsive"
import * as config from "../../config"
import {
  searchPCFilterText,
  SearchFilterType,
  searchMobileFilterText,
} from "../../types"
import * as styles from "./search-filter-chip.module.scss"

interface Props {
  type: keyof typeof SearchFilterType
  isSelected: boolean
  onSelect: (type: keyof typeof SearchFilterType) => void
  onDeselect: (type: keyof typeof SearchFilterType) => void
}

export const SearchFilterChip = React.memo((props: Props): ReactElement => {
  const isMobile = useMediaQuery({ maxWidth: config.RESPONSIVE_BREAKPOINT })

  return (
    <button
      className={props.isSelected ? styles.selectedChip : styles.unselectedChip}
      onClick={() => {
        if (props.isSelected) {
          props.onDeselect(props.type)
        } else {
          props.onSelect(props.type)
        }
      }}
    >
      <p className={styles.title}>
        {isMobile
          ? searchMobileFilterText(props.type)
          : searchPCFilterText(props.type)}
      </p>
    </button>
  )
})
